import React, { useEffect } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"

import styles from "../utils/staticPages.module.scss"
import { animate } from "../../../services/animations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyringe } from "@fortawesome/free-solid-svg-icons"

const HomeBanner = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      target: ".fade-from-left",
      duration: 1,
    })
  }, [])

  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container
        isCentered
        desktop={8}
        fullhd={6}
        customClassName="fade-from-left"
      >
        <h1 className="title is-size-1 has-text-primary">
          Have your <br /> medicines delivered
        </h1>

        {/* <p className="subtitle is-size-5 pb-3 pt-1">
          MedGrocer is an FDA-registered pharmacy{" "}
          <br className="is-hidden-mobile" />
          delivering your prescription and OTC medicines.
        </p> */}

        <div className={classNames("mt-5 mb-2", styles["buttons"])}>
          <Button
            color="primary"
            size="medium"
            onClick={() => navigate("/#order-form")}
          >
            Order Now
          </Button>
        </div>
        <div classNames="is-flex is-align-items-center mt-2">
          <FontAwesomeIcon
            icon={faSyringe}
            size="2x"
            className="has-text-primary mr-1"
          />
          <Link to="/vax" className="is-size-5 has-text-weight-bold">
            Order Vaccines
          </Link>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
