// import { AppContext } from "../../../context/AppContext"
import { sendEmail } from "../../../services/sendEmail"
import { inquiryTicketBody } from "../utils/templates/inquiryZendeskTemplate"
import { sendToZendesk } from "../../../services/zendeskService"

const generateTags = () => {
  const templateTags = ["website", "order"]
  let subject
  subject = "Order Form"
  if (process.env.GATSBY_ENV !== "production") templateTags.push("test")
  return { tags: templateTags, subject }
}

const generateInquiryTicket = (ticketData, template) => {
  const TEST_PREFIX = process.env.GATSBY_ENV !== "production" ? "[TEST] " : ""

  return {
    type: "order",
    subject: `${TEST_PREFIX}${ticketData.subject}`,
    requester: { name: ticketData.email, email: ticketData.email },
    comment: { body: template(ticketData) },
    tags: ticketData.tags,
  }
}

export const sendInquiry = async (
  documents,
  formData,
  callback,
  errorCallback
) => {
  let { tags, subject } = generateTags()

  let inquiryTicket = generateInquiryTicket(
    { ...formData, tags, subject },
    inquiryTicketBody
  )
  await sendToZendesk(inquiryTicket, documents)

  sendEmail(
    process.env.GATSBY_AWS_SES_EMAIL_TO,
    formData.email,
    process.env.GATSBY_AWS_SES_EMAIL_FROM,
    `New email from ${formData.fullName} (${formData.email})`,
    formData.emailBody,
    documents.map((document) => document.path)
  )
    .then((response) => {
      if (callback) callback()
    })
    .catch((error) => {
      if (errorCallback) errorCallback()
    })
}
